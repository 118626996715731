<template>
  <div class="accommodation-list">
    <div class="text-h5 font-weight-bold">Accommodations</div>
    <v-card class="pl-lg-9 px-sm-7 pt-sm-5 pt-3 px-3 mb-5 mt-4" width="100%" outlined>
      <div class="d-flex flex-wrap align-center mb-5">
        <v-text-field
          class="field46"
          v-model="search"
          @change="(page = 1), getData()"
          prepend-inner-icon="mdi-magnify"
          placeholder="Search Accommodation"
          :append-icon="search ? 'mdi-close' : ''"
          @click:append="clearSearch"
          hide-details
          outlined
          dense
          required
          color="primary"
          height="46"
        ></v-text-field>
        <v-spacer></v-spacer>
        <v-btn to="/accommodations-create" depressed class="primary rounded" width="136" height="30">
          <span class="text-body-2">Add new</span>
        </v-btn>
      </div>
      <div style="min-height: 67vh; height: 100%" class="view-without-scroll">
        <v-card v-if="!data.pages" tile flat color="transparent" class="d-flex justify-center align-center">
          <div>
            <v-img min-height="150" height="220" contain src="@/assets/img/bad.svg"></v-img>
            <div class="text-center text--text mt-4">Sorry, but nothing found</div>
          </div>
        </v-card>
        <v-card
          v-else
          :to="`/accommodations/${n.id}/general`"
          v-for="n in data.result"
          width="44vw"
          :key="n.id"
          outlined
          class="pa-3 mb-3 rounded-lg"
        >
          <div class="clip">{{ n.title_en }}</div>
          <!-- <div class="text-body-2 text--text">
            <span class="text-capitalize">{{ n.question_type ? n.question_type.split('_')[0] : '' }}</span> select: {{ n.answers.length }} |
            Picture {{ n.answer_with_picture ? 'included' : 'not included' }}
          </div> -->
          <div class="text--text d-flex">
            Rating:
            <v-rating
              empty-icon="mdi-star"
              full-icon="mdi-star"
              half-icon="mdi-star-half-full"
              readonly
              color="warning darken-1"
              size="16"
              :length="n.stars"
              :value="n.stars"
              v-if="n.stars"
            ></v-rating>
          </div>
        </v-card>
      </div>
      <v-divider></v-divider>
      <div class="mt-2 pb-2 px-3 d-flex align-center">
        <div class="text--text">Total {{ data.total }}</div>
        <v-spacer></v-spacer>
        <v-btn outlined small min-width="28" color="text" class="mr-1 px-0" @click="formerPage">
          <v-icon>mdi-chevron-left</v-icon>
        </v-btn>
        <span class="mx-4 text--text">{{ page }} of {{ data.pages }}</span>
        <v-btn outlined small min-width="28" color="text" class="ml-1 px-0" @click="nextPage">
          <v-icon>mdi-chevron-right</v-icon>
        </v-btn>
      </div>
    </v-card>
  </div>
</template>

<script>
export default {
  metaInfo() {
    return {
      title: this.$title(`${this.$route.name}`),
    };
  },
  data() {
    return {
      search: this.$route.query.src || '',
      page: Number(this.$route.query.page) || 1,
      error: [],
    };
  },
  mounted() {
    this.getData();
  },
  methods: {
    notifi(btn) {
      if (btn == 'search400') {
        this.$notify({
          title: 'Warning',
          message: 'This field must be at least 3 characters, maximum 10',
          type: 'warning',
          horizontalAlign: 'center',
          verticalAlign: 'top',
        });
      } else if (btn == '500') {
        this.$notify({
          title: 'Something went wrong!',
          message: 'Sorry, something went wrong, please contact support',
          type: 'error',
          horizontalAlign: 'center',
          verticalAlign: 'top',
        });
      }
    },
    clearSearch() {
      this.page = 1;
      this.search = '';
      this.getData();
    },
    nextPage() {
      if (this.page != this.data.pages) {
        this.page += 1;
        this.getData();
      }
    },
    formerPage() {
      if (this.page - 1 >= 1) {
        this.page -= 1;
        this.getData();
      }
    },
    async getData() {
      await this.$store
        .dispatch('getAccommodationContentList', { page: this.page, search: this.search })
        .then(() => {
          this.nothing = false;
        })
        .catch((e) => {
          this.getDataError(e);
        });
    },
    getDataError(e) {
      this.statusError = e.response.status;
      if (e.response.data.error[0] === 'page__out_of_bounds') {
        this.page = 1;
        this.getData();
      } else if (this.statusError >= 400 && this.statusError < 500) {
        this.notifi('search400');
      } else if (this.statusError >= 500) {
        this.notifi('500');
      }
    },
  },
  computed: {
    data() {
      return this.$store.getters.accommodationContentList;
    },
  },
  destroyed() {
    this.$store.dispatch('setAccommodationContentList');
  },
};
</script>
